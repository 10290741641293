import useLottieAnimation from '@/hooks/useLottieAnimation';
import animations from '@/components/plus/redesign/section/Hero/lottie';

interface AnimatedImageProps {
  lottieName: string;
  width?: number;
  height?: number;
  className?: string;
  alt?: string;
}

export default function AnimatedImage({
  lottieName,
  width,
  height,
  className,
  alt,
}: AnimatedImageProps) {
  const animationRef = useLottieAnimation(
    animations[lottieName as keyof typeof animations],
    {loop: false},
    {lastFrameOnReducedMotion: true},
  );

  return (
    <div
      ref={animationRef}
      className={className}
      style={{
        width,
        height,
      }}
    >
      <span className="sr-only">{alt}</span>
    </div>
  );
}
