import cn from 'classnames';
import {useRef, useEffect, useState, Fragment} from 'react';

import Image from '@/components/base/elements/Image/Image';
import AnimatedImage from '@/components/plus/redesign/section/Collage/AnimatedImage';

interface ImageProps {
  src: string;
  srcSet?: string;
  alt: string;
  animated?: boolean;
  lottieName?: string;
  width?: number;
  height?: number;
}

interface DesktopCollageProps {
  images?: ImageProps[];
}

export default function Collage({images}: DesktopCollageProps) {
  const collageRef = useRef<HTMLDivElement>(null);
  const [isCollageStartVisible, setIsCollageStartVisible] = useState(false);

  useEffect(() => {
    const collageStartObserver = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setIsCollageStartVisible(true);
        collageStartObserver.unobserve(entry.target);
      }
    });
    if (collageRef.current) {
      collageStartObserver.observe(collageRef.current);
    }
    return () => {
      collageStartObserver.disconnect();
    };
  }, []);

  const getMaxHeightFromArray = (array: ImageProps[] = []) =>
    array.reduce((maxHeight, item) => {
      const itemHeight = item.height || 0;
      return itemHeight > maxHeight ? itemHeight : maxHeight;
    }, 0);

  const arrangeIntoColumns = (imagesArray: ImageProps[] = []) => {
    const maxHeight = getMaxHeightFromArray(imagesArray);

    return imagesArray.reduce(
      (acc: ImageProps[][], item: ImageProps) => {
        const itemHeight = item.height || 0;

        const currentColumn = acc[acc.length - 1];
        const currentHeight = currentColumn.reduce(
          (sum, currentItem) => sum + (currentItem.height || 0),
          0,
        );

        if (currentHeight + itemHeight <= maxHeight) {
          currentColumn.push(item);
        } else {
          acc.push([item]);
        }

        return acc;
      },
      [[]],
    );
  };

  const renderImage = (image: ImageProps) => {
    if (image.lottieName) {
      return (
        <AnimatedImage
          className="mx-[14px] md:mx-[20px]"
          lottieName={image.lottieName}
          width={image.width}
          height={image.height}
          alt={image.alt}
        />
      );
    } else {
      const imageClassNames = cn(
        'overflow-hidden rounded-lg mx-[14px] md:mx-[20px]',
        'motion-safe:will-change-transform motion-safe:transition-transform motion-safe:duration-1000 motion-safe:ease-in-out',
        {
          'scale-100': isCollageStartVisible,
          'motion-safe:scale-125': !isCollageStartVisible,
        },
      );

      const imageStyles = {
        width: image.width,
        height: image.height,
      };

      const imageInnerClassNames = cn('motion-safe:will-change-transform', {
        'motion-safe:will-change-transform motion-safe:transition-transform motion-safe:duration-[1500ms] motion-safe:ease-in-out':
          image.animated,
        'scale-100': isCollageStartVisible,
        'motion-safe:scale-150': !isCollageStartVisible,
      });

      return (
        <div className={imageClassNames} style={imageStyles}>
          <Image
            className={imageInnerClassNames}
            src={image.src}
            srcSet={image.srcSet}
            alt={image.alt}
          />
        </div>
      );
    }
  };

  const renderRows = (rows: ImageProps[]) => {
    return rows?.map((image) => (
      <Fragment key={image.alt}>{renderImage(image)}</Fragment>
    ));
  };

  const renderColumn = (column: ImageProps[]) => {
    return (
      <div
        className="h-full flex flex-col justify-end items-center gap-6"
        key={column[0].alt}
      >
        {renderRows(column)}
      </div>
    );
  };

  const columns = arrangeIntoColumns(images)?.map((column: ImageProps[]) =>
    renderColumn(column),
  );

  return (
    <div
      className={cn(
        'flex md:justify-center w-screen',
        'motion-safe:will-change-transform motion-safe:[transition:transform_1s,_opacity_0.5s] motion-safe:ease-in-out',
        {
          'opacity-100 scale-100 translate-y-0': isCollageStartVisible,
          'motion-safe:opacity-0 motion-safe:scale-150 motion-safe:translate-y-full':
            !isCollageStartVisible,
        },
      )}
      ref={collageRef}
    >
      <div className="flex md:content-center items-stretch motion-safe:will-change-transform motion-safe:animate-plus-hero-marquee motion-safe:md:animate-none">
        {columns}
        <div className="flex md:content-center items-stretch md:hidden">
          {columns}
        </div>
      </div>
    </div>
  );
}
